.colorBase{
    margin: 5px 10px;
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    grid-column-gap: 5px;
    grid-row-gap: 10px;
}

.PaginationCenter{
    margin: auto;
    width: 100%;
    display: flex;
    /* background-color: black; */
    padding: 15px 0;
    align-self: center;
    justify-content: center;
}

.PageLoader{
    height: 99vh;
    width: 100vw;
}
.SearchFunction {
    margin: auto;
    width: 100%;
    display: flex;
    padding-top: 10px;
    align-self: center;
    justify-content: center;
}

.SearchFunction button{
    background-color: black;
    color: white;
}
.SearchFunction button, input{
    padding: 10px;
    margin: 0 5px;
}
.ErrorSearch {
    margin: auto;
    width: 100%;
    display: flex;
    flex-direction: column;
    padding-top: 10px;
    align-self: center;
    text-align: center;
    justify-content: center;
}

.ErrorSearch img{
    /* width: 50%; */
    height: 300px;
    margin: auto;
}