.colorBackground {
height: 80px;
width: 100%;
}
.modalcolorBackground {
height: 80px;
width: 100%;
display: block;
}
.modalcolorBackground1 {
height: 180px;
width: 100%;
display: flex;
align-items: flex-end;
flex-wrap: wrap;
margin-bottom: 20px;
/* display: block; */

}

.displayedCard{
    padding:5px;
    padding-bottom: 20px;
    margin: 20px;
}

.displayedCard Modal {
    background-color: black;
    margin: auto;
}

.cardTitle {
    background-color: black;
    color: white;
    text-align: center;
    width: 100%;
    /* font-family: 'Open Sans', sans-serif; */
    /* font-weight: 500 !important;
    font-size: 19px !important; */
    /* padding-left: 10px; */
    
}

button {
    background-color: black;
}