@import url(https://fonts.googleapis.com/css2?family=Open+Sans+Condensed:ital,wght@0,300;0,700;1,300&family=Open+Sans:ital,wght@0,300;0,400;0,600;0,700;0,800;1,300&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Open+Sans+Condensed:ital,wght@0,300;0,700;1,300&family=Open+Sans:ital,wght@0,300;0,400;0,600;0,700;0,800;1,300&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/* 
font-family: 'Open Sans', sans-serif;
font-family: 'Open Sans Condensed', sans-serif;

*/


/* .App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
} */

body{
  width: 100vw;
  margin: 0;
  /* background-color: black; */
}

.colorbody{
  height: 100vh;
}
.homeBase{
    display: flex;
    height: 100vh;
    align-items: center;
    /* align-items: flex-end; */
}

.homeBase h1{
    padding: 20px 25px;
    font-size: 4rem;
    font-family: 'Open Sans Condensed', sans-serif;
    font-weight: 600;
    width: 100vw;
    color: white;
    background-color: black;
}
.thinnerAnd{
    font-family: 'Open Sans Condensed', sans-serif;
    font-weight: 300;
}
.colorBackground {
height: 80px;
width: 100%;
}
.modalcolorBackground {
height: 80px;
width: 100%;
display: block;
}
.modalcolorBackground1 {
height: 180px;
width: 100%;
display: flex;
align-items: flex-end;
flex-wrap: wrap;
margin-bottom: 20px;
/* display: block; */

}

.displayedCard{
    padding:5px;
    padding-bottom: 20px;
    margin: 20px;
}

.displayedCard Modal {
    background-color: black;
    margin: auto;
}

.cardTitle {
    background-color: black;
    color: white;
    text-align: center;
    width: 100%;
    /* font-family: 'Open Sans', sans-serif; */
    /* font-weight: 500 !important;
    font-size: 19px !important; */
    /* padding-left: 10px; */
    
}

button {
    background-color: black;
}
.colorBase{
    margin: 5px 10px;
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    grid-column-gap: 5px;
    grid-row-gap: 10px;
}

.PaginationCenter{
    margin: auto;
    width: 100%;
    display: flex;
    /* background-color: black; */
    padding: 15px 0;
    align-self: center;
    justify-content: center;
}

.PageLoader{
    height: 99vh;
    width: 100vw;
}
.SearchFunction {
    margin: auto;
    width: 100%;
    display: flex;
    padding-top: 10px;
    align-self: center;
    justify-content: center;
}

.SearchFunction button{
    background-color: black;
    color: white;
}
.SearchFunction button, input{
    padding: 10px;
    margin: 0 5px;
}
.ErrorSearch {
    margin: auto;
    width: 100%;
    display: flex;
    flex-direction: column;
    padding-top: 10px;
    align-self: center;
    text-align: center;
    justify-content: center;
}

.ErrorSearch img{
    /* width: 50%; */
    height: 300px;
    margin: auto;
}
.navBackground{
    background-color: black;
    color: white;
}

.navlink{
    color: white important!;
}


.navbar-light .navbar-toggler{
    color: white;
}

/* .navbar-toggler{
background-color: yellow;
} */

.navbar-toggler-icon{
    -webkit-filter: brightness(10);
  filter: brightness(10);
}
.aboutBase{
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    width: 50%;
    min-height: 80vh;
    margin: auto;
    /* align-items: center; */
    justify-content: center;
}

.aboutBase p {
    margin: 25px 0;
    font-size: 1.3rem;
}
.LoadingPage{
    height: 99vh;
    width: 100vw;
    display: flex;
    align-items: center;
    justify-content: center;
    /* background-color: red; */
}
